.ueberuns{
  padding-top:50px;
  padding-left:13px;
  padding-right:13px;
  padding-bottom:420px;

  @include tablet-por {
    padding-top: 100px;
    padding-bottom:100px;
  }
  @include tablet-max{
    padding-top:125px;
    padding-bottom:125px;

  }
@include desk-max{
  padding-left:0px;
  padding-right:0px;
padding-bottom:263px;
}
  ul{
    margin-left:0;
    padding:0 0 0 20px;


  }
li{
  padding-bottom: 5px;
}
  p,h2,li{
    color:$w!important;
  }
  h2{
    @include fontSize(40);
    @include lineHeight(50);
  }
  h5{
    color:var(--content-uns-sublinecolor) !important;
    margin-top:0;
    padding-top:0;
  }

  .flexrow{
    @include tablet-por{

      display:flex;
      justify-content: flex-start;
      gap: 0 30px;

    section{
      flex:50%;
    }
    }
  }



}
.jobdetail{
  margin:0;
  padding:0;
  position:relative;
  top:0px;

.mastercontent{
  margin-bottom:0!important;
}
}

@include desk-max{

  .unsgallery {
    ul {
      top: -400px!important;
    }
  }

}
.imprintcontent{

  h1{
    margin-bottom:30px;
  }

@include tablet-por {
 width: 585px;

  margin-left: auto;
  margin-right: auto;
}
}
.ueberunsgallery{


  ul {
width: 100%;
    max-width: 400px;
@include tablet-por{
  max-width:unset;
}

    margin: 0 auto !important;
li{
  width:100%;
}

    img {
      width: 100%;
      height:auto;
    }
  }
@include phone{

position:relative;
    top:-440px;
    height:150px;
  }
@media screen and (min-width:420px){
  height:200px;
}

}
.unsjobdetail{
  @include phone {
    height:180px;
    position: relative;
    top: -340px;
  }
  @media screen and (min-width:420px){
    height:275px;
  }
  @include tablet-por{
    position:relative;
    top:100px!important;
    height:250px;
  }
}
.jobslider{
  width: calc(100% - 26px) !important;

  @include tablet-por{
    width:100% !important;
  }
}