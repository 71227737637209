@import "fonts.css";
@import "appcolors";
@import "mixins";
@import "pages";
.App{
  background-color: $appbgcolor ;
  font-family: "Roboto",sans-serif;


}
.homecontent{
  text-align: center;
}

#root{
  width:100%;
  overflow-x: hidden;
}