@import "../laodercss";
.jdinnercontent{
  color:var(--jobdetailheadline-mainheadline-color);
padding:50px 0;

 h1,h5{color:inherit;}

  h1{
    margin-top:25px;
    margin-bottom:33px;

    @media screen and (min-width: 600px) {
      margin-bottom: 58px;
    }
  }
}
.jodetailimagebox{

  padding-bottom:50px;

@include tablet-por{
  padding-bottom:100px;
}
  @include tablet-max{
    padding-bottom:125px;
  }

.imageslider{
  width:calc(100% - 26px);
}

}

.jobtodos{
margin-top:25px;
@include tablet-por {
  margin-top:59px;
}
@include tablet-max {
  margin-top:84px;
}

  h1{
    color:var( --jobtodos-headline-color);

    margin:0;
    @include fontSize(30);
    @include lineHeight(40);
    @include tablet-por{
      @include fontSize(32);
      @include lineHeight(50);

    }


    @include tablet-max{
      @include fontSize(40);
      @include lineHeight(50);

    }

    margin-bottom:50px;
  }
@include tablet-por{
  flex:45%;
}


  .jobbenefitslist{
    list-style-type: none;
    padding:0;
  margin-bottom:50px;
    li{
      @include fontSize(16);
      @include lineHeight(20);
      font-weight:bold;
      background-image: url("../../../globalassets/checkmark.svg");
      background-repeat: no-repeat;
      margin-bottom:20px;
      padding-left:35px;
    }
  }
}