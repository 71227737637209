
.pagehome{
  width:100%;
  height:100%;
  overflow: hidden;
}

.homecontent{
  padding-top:50px;
  text-align: center;
  h1{
    margin-top:0;
  }
}
.teaserimage{
  img{
    width:100%;
    height:auto;
  }
}