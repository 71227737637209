@import "../laodercss";



.header{
  width:100%;
  text-align: center;
  position:relative;
  background-color: $headerBackgroundColor;
  height:130px;
@include tablet-por{
  height:150px;
}

}
.headerlogo{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}