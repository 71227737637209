@import "../laodercss";

.imageslider {
  position: relative;
  width:calc(100% - 26px) ;
  overflow:hidden;


  @include tablet-max {
    width: 100%;
  }
  margin: 0 auto;

  button {
    border: 0;
    color: transparent;
    background-color: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 75px;
    cursor: pointer;
  }

  .buttonprev {
    background-image: url("../../img/arrowforward.svg");
    background-repeat: no-repeat;
    background-position: 15px 25px;
    margin-right: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .buttonnext {
    background-image: url("../../img/arrowback.svg");
    background-repeat: no-repeat;
    background-position: 17px 25px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.imagecontainer {
  display: flex;

  transition: transform 0.3s ease-in-out;


}
.imageitem {
  flex: 0 0 100%;
}

.current {
  transform: translateX(0);
}

.transitioning.left .imagecontainer {

}

.transitioning.right .imagecontainer {

}

.transitionimage {
  width: 100%;
  height: auto;
}

.buttoninactive {
  opacity: 0.5;
  cursor: unset !important;
}
