@import "../laodercss";


.jobdurationcontainer{
  color:var(--jobduration-mainheadline-color);
  h3{
    @include fontSize(20);
    @include lineHeight(25);
    @include tablet-por{
      @include fontSize(30);
      @include lineHeight(35);

    }
    font-weight: bold;


  }
  p{
    font-style: italic;
    color:inherit;
  }
}