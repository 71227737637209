@import "../laodercss";

.imageslider{
position:relative;
  width:calc(100% - 26px) ;
  @include tablet-max{
    width:100%;
  }
  margin:0 auto;
  button{
    border:0;
    color:transparent;
    background-color: rgba(0,0,0,0.5);
    width:50px;
    height:75px;
cursor: pointer;
  }

.buttonprev{
  background-image: url("../../img/arrowforward.svg");
  background-repeat: no-repeat;
  background-position: 15px 25px;
  margin-right:50px;
  position: absolute;
  top:50%;
  transform: translateY(-50%);

}
.buttonnext{
  background-image: url("../../img/arrowback.svg");
  background-repeat: no-repeat;
  background-position: 17px 25px;
  position: absolute;
  top:50%;
  right:0;
  transform: translateY(-50%);

}

}
.imageitem{

    flex: 0 0 100%;
    transition: transform 0.3s ease-in-out;


  img{
width:100%;
  }
}
.transitionimage{

}
.transitioning {
  transition: opacity 0.5s ease-in-out; /* Übergangseffekt für Opazität */
  opacity: 0;


}
.active{
  opacity: 1;
}
.imagegallery{
width:100%;
@include tablet-max{
  height:200px;
}
  img{
    width:100%;
    height:auto;
  }
  ul {
    list-style-type: none;
    margin-left: 0;
padding:0;
li{
  margin-bottom:25px;
}
    @include tablet-por{

      overflow: hidden;
     position:relative;
      top:-180px;

      display:flex;
      justify-content: flex-start;
      gap:0;
      li{
        flex: 50%;

      }
      li:first-of-type{
        margin-right:30px;
      }
    }
@include desk-max{
  top:-280px;
}

  }
  }
.imageslide_imagegallery{
  @include tablet-por {
    height: 300px;
  }
}

@keyframes imageTransfer {
from{
  opacity: 0;
}
  to{
    opacity: 1;
  }
}@keyframes imageFadeOut {
from{
  opacity: 1;
}
  to{
    opacity: 0;
  }
}
 .transitionimage{
   animation: imageTransfer 0.4s;
 }
.buttoninactive{
  opacity:0.5;
  cursor: unset!important;
}
.imagecontainer {
  display: flex;
  overflow: hidden;
}
.singleimage{
  img{
    width:100%;
    height:auto;
  }
}