@import "../laodercss";

footer{
  width:100%;
padding: 50px 0;

  -webkit-box-sizing: border-box;  box-sizing: border-box;
  background-color: var(--footer-background-color);
@include tablet-por{
  height:125px;

}
  @include tablet-max{
    padding:0;
  }
.inlay{
  max-width: $maxwidth;
  margin:0 auto;
  position:relative;
}
.flexrow{
  display:flex;
flex-direction: column;
@include tablet-por{
flex-direction: row;
  gap: 13px 30px;
}
}
  .evolvelogo{
    text-align: center;
order: 2;

    margin-top:10px;
@include tablet-por{
  text-align: left;
  margin-top:0;
  position:relative;
  top:2px;
  padding-left:13px;
order:1;
}


    @include tablet-max {
    padding-top:50px;
    }
    }
  @include desk-max{
    padding-left:0;
  }
}


.footernavigation{
  list-style-type: none;
  display: flex;
  gap: 0 30px;
  width:100%;
  flex:100%;
  order:1;
  position:relative;
  top:-21px;
  padding:0;
  margin-left:0;
  justify-content: center;
  text-align: center;
  @include tablet-por {
justify-content: flex-end;
padding-right:13px;

    order:2;
    top:-14px;
  }
  @include tablet-max{
    top:40px;
  }
  @include desk-max{
    padding-right:0;
  }
  li{
    a{
      @include fontSize(14);
      text-decoration: none;
      color:$footerlinkcolor;
    }
  }
}