@import "../laodercss";
.jobstylewrapper {
  max-width: 770px;
  margin:65px auto 80px auto;
  height:230px;
  width:calc( 100% - 26px);

  @include tablet-por {
    height: 100px;
    margin:0 auto;
  }
  position: relative;
  top: -70px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .itemlist {

    position:absolute;
    left:50%;
    top:50%;

    -webkit-transform: translate(-40%,-61%);    -ms-transform: translate(-40%,-61%);
    transform: translate(-40%,-61%);
    list-style-type: none;
    padding-left:0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @include tablet-por{
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      width:100%;
      -webkit-transform: translate(-48%,-61%);
      -ms-transform: translate(-48%,-61%);
      transform: translate(-48%,-61%);

    }
    li {
      @include fontSize(18);
      @include lineHeight(21);
      font-weight: bold;
      height:40px;
      padding-top:5px;
      background-repeat: no-repeat;

    color:var(--job-location--textcolor);
      margin-right:50px;

    }
    li.itemcity{
      background-image: url("../../../globalassets/001-location.svg");
      padding-left:50px;
      fill: var(--job-location--iconcolor);
    }
    li.itemjobtype{
      background-image: url("../../../globalassets/002-clock.svg");
      padding-left:50px;
    }
    li.itemjobtime{
      background-image: url("../../../globalassets/003-calendar.svg");
      padding-left:50px;
    }


  }

  a.ctabutton{
    position:absolute;
    top:300px;
    @include tablet-por{
      top:150px;
    }
    white-space: nowrap;
    left:50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom:50px;
  }
}
.offercontent{
  text-align: center;
  box-sizing: border-box;
  padding:44px 0 50px 0;
  height:360px;
  @media screen and (min-width:470px){
    height:340px;

      }
  @include tablet-por{
    padding:69px 0 50px 0;
    height:340px;
  }

  @include tablet-max{
    height:250px;
    text-align: left;
    -webkit-box-flex:585px;
    -ms-flex:585px;
    flex:585px;
    left:-50px;
    padding:65px 0 50px 0;

  }
  position:relative;

  -webkit-box-sizing: border-box;

  box-sizing: border-box;
  h2{
    @include fontSize(25);
    @include lineHeight(35);
    padding:0;
width:334px;
margin:0 auto;
    padding-bottom:10px;
    @include tablet-por{
      width:auto;

      @include fontSize(35);
      @include lineHeight(45);
    }
    @include tablet-max{
      margin-left:65px;
    }

  }
  p{
    padding: 0 50px;

    @include tablet-por{
      padding:0 125px;
    }

    @include tablet-max{
      max-width:390px;
      padding:0;
      margin-left:65px;
    }
  }
}
.offerbutton{
  position:absolute;
  bottom:70px;
  right:50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  white-space: nowrap;
a{
  background-color: var(--joboffercontent-ctabackgroundcolor);
  color:var(--joboffercontent-ctacontentcolor);

  &:hover{
    background-color:var(--joboffercontent-ctabackhovergroundcolor);
  }

}
@media screen and (min-width:470px){
  bottom:89px;
}
  
  @include tablet-por{
    bottom:90px;
  }

  @include tablet-max{
    right:-30px;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    bottom:115px;
  }
}