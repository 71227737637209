@import "../laodercss";
.ctabutton{
  color:var(--ctabutton-textcolor);
  text-decoration: none;
  background-color: var(--ctabutton-background-color);
  border-radius: 5px;
  font-size: 18px;
  line-height: 21px;
text-transform: uppercase;
  padding:17px 35px 14px 35px;
  font-weight: bold;
  @include setTransition();
  &:hover{
    background-color:var(--ctabutton-background-hover-color);
  }
@include tablet-max{

}
}